<template>
  <div>
    <div class="md-layout-item md-medium-size-50 md-xsmall-size-60 md-size-50">
      <md-field class="filter-input" data-v-step="2">
        <label>Filter</label>
        <md-input @keyup="(e) => getDevices(1)" v-model="filter"></md-input>
      </md-field>
    </div>
    <md-table v-if="data.data" v-model="data.data" data-v-step="4">
      <md-table-row
        class="pointer"
        @click="goToProfile(item.id)"
        slot="md-table-row"
        slot-scope="{ item }"
      >
        <md-table-cell md-label="Identifier">
          <a>{{ item.identifier }}</a>
          <!-- {{ item.identifier }} -->
        </md-table-cell>
        <md-table-cell md-label="Alias">{{ item.alias }}</md-table-cell>

        <md-table-cell
          md-label="Type"
          v-if="getOrientation === 90 || getOrientation === -90"
          >{{ item.device_type }}</md-table-cell
        >
        <md-table-cell
          md-label="Technology"
          v-if="getOrientation === 90 || getOrientation === -90"
          >{{ item.technology_type }}</md-table-cell
        >
        <md-table-cell md-label="Last seen">{{
          item.last_update ? item.last_update : "Never seen"
        }}</md-table-cell>
      </md-table-row>
    </md-table>
    <pagination :data="data" @pagination-change-page="getDevices"></pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "mobile-devices-table",
  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      data: {},
      filter: "",
    };
  },
  computed: {
    ...mapGetters("screen", ["getOrientation"]),
  },
  methods: {
    getDevices(page = 1) {
      this.$store
        .dispatch("device/getDevices", { page: page, filter: this.filter })
        .then((data) => {
          this.data = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goToProfile(id) {
      this.$router.push({
        name: "mobileDeviceMonitoringLatest",
        params: { device: id },
      });
    },
  },
  mounted() {
    this.getDevices();
  },
};
</script>