<template>
  <div>
    <tgl-card-header-mobile
      ><h1>Dispositivos de la organización</h1></tgl-card-header-mobile
    >
    <tgl-card-content-mobile>
      <devices-table></devices-table>
    </tgl-card-content-mobile>
  </div>
</template>

<script>
import TglCardHeaderMobile from "@/components/Mobile/Cards/TglCardHeaderMobile.vue";
import TglCardContentMobile from "@/components/Mobile/Cards/TglCardContentMobile.vue";
import DevicesTable from "@/components/Mobile/Devices/DevicesTable.vue";
export default {
  name: "mobile-devices-table-page",
  components: { TglCardHeaderMobile, TglCardContentMobile, DevicesTable },
};
</script>

<style lang="sass">
</style>